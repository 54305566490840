import { SEO } from '@/components/SEO'
import { useTheme } from '@/store/theme'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React from 'react'

const FourOhFourPage: React.FC = () => {
  const theme = useTheme()

  return (
    <>
      <SEO
        title="404: Not found"
        metaDescription="You have hit a 404: not found page"
      />

      <div
        css={css`
          height: 100vh;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          padding: 0 5%;
          text-align: center;
        `}
      >
        <h1
          css={css`
            ${fluidFontSize(30, 48, 320, 1280)};
            margin-bottom: 1em;
            font-weight: 900;

            color: ${theme.colors.grays[8]};
          `}
        >
          404: this page doesn’t exist.
        </h1>
        <Link
          to="/"
          css={css`
            padding: 1em 2em;
            background: ${theme.colors.grays[7]};
            border-radius: ${theme.radii.pill};

            color: ${theme.colors.grays[1]};
            font-size: 0.875rem;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            text-decoration: none;

            transition: all 0.1s ease-out;

            &:hover {
              background: ${theme.colors.grays[6]};
              color: ${theme.colors.grays[0]};
            }
          `}
        >
          Back to home
        </Link>
      </div>
    </>
  )
}

export default FourOhFourPage
